import React from 'react'
import PropTypes from 'prop-types'
import HeroBannerContent from './HeroBannerContent'
import styles from './index.module.scss'
import DebtReliefCard from './DebtReliefCard'
import TestimonialCard from './TestimonialCard'

const HeroBannerClientPoints = ({
  content,
  isDefaultLang,
  useTestimonialFormat,
}) => (
  <>
    <div
      className={`flex items-center justify-center ${styles.defaultBackgorund}`}
      data-testid="main-div"
      id="hero-banner"
    >
      <div className="flex h-full w-full flex-wrap">
        <div className="px-5 pt-10 sm:w-full lg:w-1/2 lg:p-10 ">
          <HeroBannerContent content={content} isDefaultLang={isDefaultLang} />
        </div>
        <div className="sm:w-full lg:w-1/2">
          {useTestimonialFormat ? <TestimonialCard /> : <DebtReliefCard />}
        </div>
      </div>
    </div>
  </>
)

HeroBannerClientPoints.defaultProps = {
  isDefaultLang: true,
  useTestimonialFormat: false,
}

HeroBannerClientPoints.propTypes = {
  content: PropTypes.object.isRequired,
  isDefaultLang: PropTypes.bool,
  useTestimonialFormat: PropTypes.bool,
}

export default HeroBannerClientPoints
