import Image from 'next/image'
import Img from 'src/components/shared/Img'

const TestimonialCard = () => {
  return (
    <div className="flex flex-col items-center p-6 text-center">
      {/* Profile Image & Speech Bubble */}
      <div className="relative mb-6 h-80 w-80 lg:h-100 lg:w-100">
        <div className="shadow-lg h-full w-full overflow-hidden rounded-full border-4 border-white">
          <Img
            src="/next-assets/image.png"
            alt="Client Testimonial"
            className="h-full w-full object-cover"
          />
        </div>
        {/* Speech Bubble */}
        <div className="absolute top-5 right-3 flex items-center rounded-lg lg:top-0 lg:right-0">
          <Image
            src="/next-assets/heart-icon.png"
            alt="Client Testimonial"
            className="h-[50px] w-[50px] lg:h-[81px] lg:w-[81px]"
            width={81}
            height={82}
            priority
          />
        </div>
      </div>

      {/* Testimonial Text */}
      <div className="z-50 mt-[-50px] max-w-sm rounded-lg bg-blue-700 py-[8px] px-[12px] text-sm text-white">
        “Within a year, my first card was paid off. Then the second, then the
        third... After only a few years, all my debts were paid off.”
        <span className="font-bold"> – Patti B.</span>
      </div>

      {/* Disclaimer */}
      <p className="mt-4 max-w-xs text-xs italic text-gray-500">
        Actual client of Freedom Debt Relief. Individual results are not typical
        and will vary.
      </p>
    </div>
  )
}

export default TestimonialCard
