import React from 'react'

const DebtReliefCard = () => {
  return (
    <div className="relative mx-auto mt-10 max-w-md p-6 text-center">
      {/* Circular Image */}
      <div className="w-90 h-90 shadow-lg relative mx-auto ml-10 overflow-hidden rounded-full border-4 border-white">
        <img
          src="/next-assets/image.png"
          alt="Client"
          className="h-full w-full object-cover"
        />
      </div>

      {/* Floating Labels */}
      <div
        className={`left-50 text-black shadow absolute top-10 ml-[60px] mt-[50px] -translate-x-1/3 rounded-full bg-orange-250 px-4 py-1 md:mt-[90px] lg:left-0 lg:py-2`}
      >
        Resolved $17,183 in debt
      </div>
      <div
        className={`bg-green-500 shadow absolute top-28 right-20 mt-[50px] translate-x-1/3 rounded-full bg-green-120 px-4 py-1 text-white md:mt-[70px] lg:right-0 lg:py-2`}
      >
        Settled 4 enrolled debts!
      </div>
      <div className="left-35 shadow absolute bottom-44 ml-[70px] mt-[40px] -translate-x-1/3 rounded-full bg-blue-500 px-4 py-1 text-white lg:bottom-52 lg:left-0 lg:py-2">
        1 monthly payment of $362
      </div>
      <div className="shadow absolute bottom-25 right-12 mr-[20px] translate-x-1/3 rounded-full bg-blue-700 px-4 py-1 text-white md:mr-[0px] lg:py-2">
        Patti B., Albany, NY
      </div>

      {/* Footer Text */}
      <p className="mt-10 ml-12 text-sm text-gray-500">
        Actual client of Freedom Debt Relief.
        <br />
        Individual results are not typical and will vary.
      </p>
    </div>
  )
}

export default DebtReliefCard
