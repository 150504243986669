import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'src/components/shared/Slider'
import { useAnalytics } from '../../../../../common/analytics'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { useLeadId } from '../../../../../common/lead-id'
import styles from './index.module.scss'
import { getCookies } from 'src/common/helpers'
import { useNavigateToApply } from 'src/common/hooks/useNavigateToApply'
import AnimatedArrowRedCTA from 'src/components/shared/CallToActionButton/AnimatedArrowRedCTA'
import { TrustPilot } from 'src/components/shared/TrustMarkerWidgets'
import classNames from 'classnames'

const HeroBannerContent = ({ content, isDefaultLang = true }) => {
  const {
    title,
    sliderTitle,
    buttonText,
    onSliderChange = () => {},
    defaultDebtAmount,
  } = content
  const { APPLY_FDR_URL } = getConfig()?.publicRuntimeConfig
  const { query: baseQuery } = useRouter()
  const { track, utm_source } = useAnalytics()
  const cookieLeadId = useLeadId()
  const cookies = getCookies()
  const APPLY_URL = isDefaultLang
    ? `${APPLY_FDR_URL}/home/`
    : `${APPLY_FDR_URL}/espanol/`
  const [estimatedDebt, setDebt] = useState(defaultDebtAmount || 25000)
  const { navigateToApply } = useNavigateToApply({
    utm_source,
    estimated_debt: estimatedDebt,
  })

  let query = useMemo(() => {
    const urlParams = {
      from: 'fdr',
      utm_source,
      ...baseQuery,
      estimated_debt: estimatedDebt,
      leadId: cookieLeadId,
      visit_id: cookies?.eh_visit_id || null,
    }

    for (let key in urlParams) {
      if (!urlParams[key]) {
        delete urlParams[key]
      }
    }

    const q = new URLSearchParams(urlParams)

    return q.toString()
  }, [baseQuery, cookieLeadId, cookies?.eh_visit_id, estimatedDebt, utm_source])

  const handleSliderChange = useCallback((event) => {
    const { value } = event.target
    setDebt(value)
    onSliderChange(value)
  }, [])

  const handleSliderClick = useCallback(
    (e) => {
      e.preventDefault()

      const data = {
        nav_link_section: 'Page',
        page_section: sliderTitle,
        click_type: 'Button Click',
        click_id: buttonText,
        click_text: `FDR-Web | ${buttonText}`,
        form_ss_amount: +estimatedDebt,
        track_event: 'button_click',
      }

      if (typeof track === 'function') {
        track(data, {}, 'button_click')
      }

      navigateToApply()
    },
    [sliderTitle, buttonText, estimatedDebt, track, navigateToApply]
  )

  const handleSliderRelease = useCallback(
    (e) => {
      const data = {
        nav_link_section: 'Page',
        page_section: sliderTitle,
        click_type: 'Button Click',
        click_id: 'slider release',
        click_text: `$${e.target.value}`,
        form_ss_amount: +e.target.value,
        track_event: 'button_click',
      }
      if (typeof track === 'function') {
        track(data, {}, 'button_click')
      }
    },
    [track, sliderTitle]
  )

  const handlePreviewClick = () => {
    track(
      {
        event_type: 'track',
        click_id: 'Trustpilot',
        click_type: 'Link Click',
        additional_info: {
          section: 'trust-marker-truspilot',
          click_url: content?.trustPilot?.link || '',
        },
      },
      {},
      'button_click'
    )
  }

  return (
    <div
      className="flex flex-col bg-gray-25 md:flex-row xl:justify-center"
      id="hero-content-v10"
    >
      <div className="max-w-full text-center md:w-full">
        <div className="text-center text-[14px] font-bold uppercase leading-[120%] tracking-[0.25em] text-content-information">
          debt relief PROGRAM
        </div>
        <span className="whitespace-pre-wrap py-5 text-[36px] lg:text-[48px]">
          {title}
        </span>
        <div
          className={`my-3 flex flex-col items-center rounded-[16px] bg-white py-4 text-center shadow-2xl lg:my-4`}
        >
          <h2 className={`mb-[16px] text-[20px] font-normal`}>{sliderTitle}</h2>
          <Slider
            valueClassName={`text-content-information text-[28px] w-full text-center mb-[0.2rem]`}
            containerClassName={classNames('w-4/5 mb-10', styles.slider)}
            sliderClassName={
              'mb-3 w-full appearance-none rounded-lg bg-gray-55 bg-slider-track-2 bg-no-repeat outline-none h-[4px] w-full'
            }
            showMinMaxLabels
            onChange={handleSliderChange}
            start={+estimatedDebt}
            name="estimateSlider"
            step={1000}
            min={1000}
            max={100000}
            unit="$"
            onSliderRelease={handleSliderRelease}
          />
          <AnimatedArrowRedCTA
            id="hero-banner-content-cta"
            buttonText={buttonText}
            url={`${APPLY_URL}?${query}`}
            onClick={handleSliderClick}
            className="w-full max-w-[327px] lg:max-w-[327px]"
          />
          {content.newTrustpilotItem && (
            <TrustPilot
              {...content.trustPilot}
              onReviewClick={handlePreviewClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}

HeroBannerContent.propTypes = {
  content: PropTypes.object.isRequired,
  isDefaultLang: PropTypes.bool,
  shortView: PropTypes.bool,
}
export default HeroBannerContent
